<template>
  <section>
    <section class="search-container">
      <a-row type="flex" :gutter="20">
        <a-col>
          <a-form
            :form="purchaseForm"
            layout="inline"
            @submit="submitForm"
            class="m-t-14"
          >
            <a-form-item label="供货商">
              <a-input
                autocomplete="off"
                v-decorator="[
                  'supplier',
                  {
                    rules: [
                      { max: 30, message: '最大输入30个汉字' },
                    ],
                  },
                ]"
                placeholder="请输入"
              />
            </a-form-item>

            <a-form-item label="单据时间">
              <a-date-picker v-decorator="['orderDate']" />
            </a-form-item>
            <a-form-item label="采购单据编号">
              <a-input v-decorator="['code']" placeholder="请输入" />
            </a-form-item>
            <!-- <a-form-item>
              <a-form-item label="制单人">
                <span style="color:rgba(255, 255, 255, 0.8)">宋一</span>
              </a-form-item>
            </a-form-item> -->
            <section class="list-table-container list-table">
              <a-table
                :bordered="false"
                :data-source="dataSource"
                :pagination="false"
                size="small"
                class="m-t-18"
                :columns="columns"
              >
                <template slot="operation" slot-scope="list, text">
                  <a @click="delDataSource(text.id)">删除</a>
                </template>
                <template slot="orderQty" slot-scope="list, text">
                  <a-input
                    :min="0"
                    :class="text.orderQtyStatus == 'true' ? 'tip' : ''"
                    v-model="text.orderQty"
                    type="number"
                    style="width: 80px"
                  />
                </template>
                <template slot="oriUnitPrice" slot-scope="list, text">
                  <a-input
                    :class="text.oriUnitPriceStatus == 'true' ? 'tip' : ''"
                    :min="0"
                    v-model="text.oriUnitPrice"
                    :step="0.01"
                    type="number"
                    style="width: 80px"
                  />
                </template>
                <template slot="discount" slot-scope="list, text">
                  <!-- :class="text.discountStatus == 'true' ? 'tip' : ''" -->
                  <a-input
                    v-model="text.discount"
                    :min="0"
                    :step="0.01"
                    type="number"
                    style="width: 80px"
                  />
                </template>

                <template slot="actualAmount" slot-scope="list, text">
                  <a-input
                    :class="text.actualAmountStatus == 'true' ? 'tip' : ''"
                    v-model="text.actualAmount"
                    :min="0"
                    :step="0.01"
                    type="number"
                    style="width: 80px"
                  />
                </template>
                <template slot="factPrice" slot-scope="list, text">
                  <span>{{
                    (text.orderQty * text.oriUnitPrice).toFixed(2)
                  }}</span>
                </template>
                <template slot="actualPrice" slot-scope="list, text">
                  <!-- {{ (text.factPrice/text.orderQty).toFixed(2) }} -->
                  <!-- <span>{{ text.actualAmount }}</span>
                  <span>{{ text.orderQty }}</span> -->

                  <span>{{
                    text.orderQty == "0"
                      ? 0
                      : (text.actualAmount / text.orderQty).toFixed(2)
                  }}</span>
                </template>
              </a-table>
              <a-row
                class="search-container addData"
                type="flex"
                justify="center"
              >
                <a-button @click="addData">新增</a-button>
              </a-row>
              <a-row class="totalNum" type="flex" justify="start">
                <span style="width: 63px">合计</span>
                <span style="width: 111px"></span>
                <span style="width: 111px"></span>
                <span style="width: 112px"></span>
                <span style="width: 112px"></span>
                <span style="width: 112px">{{ stockData }}</span>
                <span style="width: 146px">{{ buyTotalNum }}</span>
                <span style="width: 146px"></span>
                <span style="width: 146px"></span>
                <span style="width: 112px">{{ buyTotalPrice }}</span>
                <span style="width: 146px">{{ reallyCount }}</span>
                <span style="width: 112px"></span>
              </a-row>
            </section>

            <a-row>
              <a-textarea
                class="textarea"
                v-model="remarks"
                placeholder="暂无备注信息"
                :rows="4"
              />
              <!-- <a-form-item label="供货商">
                <a-textarea
                  placeholder="暂无备注信息"
                  v-decorator="['suppliers']"
                  :rows="4"
                />
              </a-form-item> -->
            </a-row>
            <section class="form-container">
              <a-row class="form-btn-group">
                <a-button
                  html-type="submit"
                  class="btn-save"
                  :disabled="submitLoadding"
                  :loading="submitLoadding"
                  ><a-icon type="save" />保存</a-button
                >
                <a-button class="btn-cancel" @click="$router.go(-1)"
                  >取消</a-button
                >
              </a-row>
            </section>
          </a-form>
        </a-col>
      </a-row>
    </section>

    <!-- 模态框 -->
    <a-modal
      centered
      width="880px"
      class="modal-container"
      :title="typeModal.title"
      v-model="typeModal.visible"
      :destroyOnClose="true"
      :footer="false"
    >
      <a-form :form="materialform" @submit="addMaterial">
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="材料"
        >
          <a-cascader
            :options="options"
            @change="(value) => onChangeMaterial(value, options)"
            placeholder="请选择"
            :display-render="displayRender"
            v-decorator="[
              'companyNames',
              { rules: [{ required: true, message: '类型名称不能为空' }] },
            ]"
          />
        </a-form-item>

        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="分类名称"
        >
          <section class="list-table-container list-table">
            <a-table
              :rowSelection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }"
              :bordered="false"
              :data-source="materialSource"
              size="small"
              class="m-t-36"
              :columns="materialColumns"
              :pagination="false"
              :scroll="{ y: 500 }"
            >
            </a-table>
          </section>
        </a-form-item>
        <a-row class="form-btn-group">
          <a-button class="btn-save" html-type="submit"
            ><a-icon type="save" />保存</a-button
          >
          <a-button class="btn-cancel" @click="typeModal.visible = false"
            >取消</a-button
          >
        </a-row>
      </a-form>
    </a-modal>
  </section>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      typeModal: {
        visible: false,
        title: "添加",
      },
      materialColumns: [
        { title: "序号", dataIndex: "order", align: "center" },
        { title: "品牌", dataIndex: "brand", align: "center" },
        { title: "规格型号", dataIndex: "type", align: "center" },
        { title: "库存数量", dataIndex: "stockQty", align: "center" },
        {
          title: "计量单位",
          dataIndex: "unit",
          align: "center",
        },
      ],
      delStatus: null,
      materialSource: [],
      materialPageNum: 1,
      materialTotal: 0,
      selectedRowKeys: [],
      selectedSourceData: [],
      unitPrice: "",
      purchaseForm: this.$form.createForm(this),
      materialform: this.$form.createForm(this),
      columns: [
        { title: "序号", dataIndex: "order", align: "center", width: 100 },
        {
          title: "材料名称",
          dataIndex: "name",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "name" },
        },
        {
          title: "品牌",
          dataIndex: "brand",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "brand" },
        },
        {
          title: "规格型号",
          dataIndex: "type",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "type" },
        },
        {
          title: "计量单位",
          dataIndex: "unit",
          align: "center",
          width: 200,
        },
        {
          title: "剩余库存数量",
          dataIndex: "stockQty",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "stockQty" },
        },
        {
          title: "采购数量",
          dataIndex: "orderQty",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "orderQty" },
        },
        {
          title: "购货单价(元)",
          dataIndex: "oriUnitPrice",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "oriUnitPrice" },
        },
        {
          title: "折扣率%",
          dataIndex: "discount",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "discount" },
        },
        {
          title: "采购金额(元)",
          dataIndex: "factPrice",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "factPrice" },
        },
        {
          title: "实采金额(元)",
          dataIndex: "actualAmount",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "actualAmount" },
        },
        {
          title: "实采单价(元)",
          dataIndex: "actualPrice",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "actualPrice" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "operation" },
        },
      ],
      dataSource: [],
      options: [],
      totaldataSource: [],
      remarks: "",
    };
  },
  created() {
    this.getOrderNextCode();
  },
  computed: {
    //采购数量
    buyTotalNum() {
      let d = 0;
      this.dataSource.forEach((item) => {
        d = parseInt(item.orderQty) + d;
      });
      return d;
    },
    // 采购金额
    buyTotalPrice() {
      let d = 0;
      this.dataSource.forEach((item) => {
        d = parseInt(item.orderQty) * item.oriUnitPrice + d;
        // console.log(d, "dddd", parseInt(item.ind5) * item.ind6);
      });
      return d.toFixed(2);
    },
    // 剩余库存数量总数
    stockData() {
      let d = 0;
      this.dataSource.forEach((item) => {
        d = parseInt(item.stockQty) + d;
      });

      return d;
    },
    // 实采金额总数
    reallyCount() {
      let d = 0;
      this.dataSource.forEach((item) => {
        d = Number(item.actualAmount) + d;
      });
      return d.toFixed(2);
    },
    submitLoadding() {
      return this.$store.state.submitLoadding;
    },
  },
  methods: {
    moment,
    // 材料分页
    changePageNum(p) {
      this.materialPageNum = p.current;

      this.getSkuList();
    },
    // 材料下拉
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    // 选择添加材料
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 选择材料
    onChangeMaterial(value) {
      this.categoryId = value[value.length - 1];
      this.getSkuList();
    },

    // 材料数据
    getSkuList() {
      this.$api
        .getSkuList({
          categoryId: this.categoryId,
          current: this.materialPageNum,
          size: 10000,
        })
        .then((res) => {
          if (res.code == "200") {
            let records = res.data.records;
            this.materialTotal = res.data.total;
            if (records && records.length > 0) {
              records.forEach((item, index) => {
                item.order = index + 1;
                item.key = item.id;
                item.skuID = item.id;
                item.num = 0;
                item.orderQty = 0;
                item.oriUnitPrice = 0;
                // item.discountStatus = "false";
                item.actualAmountStatus = "false";
                item.orderQtyStatus = "false";
                item.oriUnitPriceStatus = "false";
                item.discount = 0;
                item.factPrice = 0;
                item.actualAmount = 0;
                item.actualPrice = 0;
              });

              this.materialSource = records;
              // if (this.selectedRowKeys.length > 0) {
              //   let arr = this.array_diff(records, this.selectedRowKeys); //去重
              //   arr.forEach((item, index) => {
              //     item.order = index + 1;
              //     item.key = item.id;
              //     item.skuID = item.id;
              //     item.num = 0;
              //     item.orderQty = 0;
              //     item.oriUnitPrice = 0;
              //     item.discount = 0;
              //     // item.discountStatus = "false";
              //     item.actualAmountStatus = "false";
              //     item.orderQtyStatus = "false";
              //     item.oriUnitPriceStatus = "false";
              //     item.factPrice = 0;
              //     item.actualAmount = 0;
              //     item.actualPrice = 0;
              //   });
              //   this.materialSource = arr;
              // } else {
              //   records.forEach((item, index) => {
              //     item.order = index + 1;
              //     item.key = item.id;
              //     item.skuID = item.id;
              //     item.num = 0;
              //     item.orderQty = 0;
              //     item.oriUnitPrice = 0;
              //     // item.discountStatus = "false";
              //     item.actualAmountStatus = "false";
              //     item.orderQtyStatus = "false";
              //     item.oriUnitPriceStatus = "false";
              //     item.discount = 0;
              //     item.factPrice = 0;
              //     item.actualAmount = 0;
              //     item.actualPrice = 0;
              //   });

              //   this.materialSource = records;
              // }
            }
          }
        });
    },
    // 入库编码
    getOrderNextCode() {
      this.$api.getOrderNextCode().then((res) => {
        if (res.code == "200") {
          this.code = res.data;
          // 初始化 编码和时间
          this.purchaseForm.setFieldsValue({
            orderDate: moment(new Date(), "YYYY/MM/DD"),
            code: this.code,
          });
        }
      });
    },
    // 添加保存材料
    addMaterial(e) {
      e && e.preventDefault();
      this.materialform.validateFields((err, values) => {
        if (!err) {
          // 当前材料id
          let nowmaterialId =
            values.companyNames[values.companyNames.length - 1];
          console.log(this.options, nowmaterialId);
          this.options.forEach((item) => {
            if (item.id == nowmaterialId) {
              this.addMaterialName = item.name;
              return;
            }
            if (item.children && item.children.length > 0) {
              item.children.forEach((items) => {
                if (items.id == this.categoryId) {
                  this.addMaterialName = items.name;
                  return;
                }
              });
            }
          });
          // let source = []; //选择的材料
          this.selectedRowKeys.forEach((item) => {
            this.materialSource.forEach((v) => {
              if (item == v.key) {
                this.dataSource.push(v);
              }
            });
          });
          let uniq = this.uniq(this.dataSource, "id");
          this.dataSource = uniq;
          this.dataSource.forEach((item, index) => {
            item.order = index + 1;
          });
          //console.log(this.selectedRowKeys, this.materialSource);
          // this.dataSource.forEach((item, index) => {
          //   item.order = index + 1;
          // });
          // let uniq = this.uniq(this.totaldataSource, "id");

          // this.dataSource = uniq;
          // this.dataSource.forEach((item,index)=>{
          //   item.key=index+1
          // })
          // this.transData=this.dataSource

          this.typeModal.visible = false;
        }
      });
    },
    submitForm(e) {
      e && e.preventDefault();
      let formVal = this.purchaseForm.getFieldsValue();

      let params = {};
      params.code = formVal.code;
      params.orderDate = formVal.orderDate
        ? formVal.orderDate.format("YYYY-MM-DD")
        : "";
      params.supplier = formVal.supplier ? formVal.supplier : "";
      params.remarks = this.remarks;

      params.detailList = [];
      let status = "";
      this.dataSource.forEach((item) => {
        // 做校验
        if (
          item.actualAmount == "0" ||
          item.orderQty == "0" ||
          item.oriUnitPrice == "0"
        ) {
          status = 0;
          // item.discountStatus = item.discount == "0" ? "true" : "false";
          item.actualAmountStatus = item.actualAmount == "0" ? "true" : "false";
          item.orderQtyStatus = item.orderQty == "0" ? "true" : "false";
          item.oriUnitPriceStatus = item.oriUnitPrice == "0" ? "true" : "false";
          // this.$message.error("请填写完整信息再提交!");
        } else {
          status = 1;
          // item.discountStatus = "false";
          item.actualAmountStatus = "false";
          item.orderQtyStatus = "false";
          item.oriUnitPriceStatus = "false";
          params.detailList.push({
            actualAmount: item.actualAmount,
            discount: item.discount,
            orderQty: item.orderQty,
            oriUnitPrice: item.oriUnitPrice,
            skuId: item.id,
          });
        }
      });
      if (status == "1") {
        this.$store.commit("SET_SUBMITLOADDING", true);
        this.$api.addOrder(params).then((res) => {
          if (res.code == "200" && res.data) {
            this.$router.push({ path: "/mms/purchase/list" });
          } else if (res.code == "999") {
            this.$message.error("目前仓库并无存库,请重新选择!");
          }
        });
      } else {
        this.$message.error("请填写完整信息再提交!");
      }
    },

    // 新增
    addData() {
      this.typeModal.visible = true;
      this.materialSource = [];

      // this.selectedRowKeys = [];
      // // 查询材料
      this.$api.getCategoryList().then((res) => {
        if (res.code == "200") {
          let options = res.data;
          let arr = this.formTreeData(options);
          this.options = arr;
        }
      });
    },
    // 删除
    delDataSource(id) {
      // console.log(this.selectedRowKeys,id)
      this.dataSource = this.dataSource.filter((item) => {
        if (item.id != id) return item;
      });
      this.dataSource.forEach((item, index) => {
        item.order = index + 1;
      });
      this.selectedRowKeys = this.selectedRowKeys.filter((item) => {
        if (item != id) return item;
      });
    },
    // 树状图
    formTreeData(arr) {
      arr.forEach((item) => {
        item.value = item.id;
        item.label = item.name;
        // item.scopedSlots = { title: "value" };
        if (item.children && item.children.length > 0) {
          this.formTreeData(item.children);
        }
      });
      return arr;
    },
    uniq(arr, key) {
      let newobj = {};
      let newArr = [];
      for (let i = 0; i < arr.length; i++) {
        var item = arr[i];
        if (!newobj[item[key]]) {
          newobj[item[key]] = newArr.push(item);
        }
      }
      return newArr;
    },
    // 数组去重
    array_diff(a, b) {
      for (var i = 0; i < b.length; i++) {
        for (var j = 0; j < a.length; j++) {
          if (a[j].id == b[i]) {
            a.splice(j, 1);
            j = j - 1;
          }
        }
      }
      return a;
    },
  },
};
</script>
<style lang="scss" scoped>
.tip {
  border: 1px solid red;
  color: red;
}
/deep/.ant-calendar-picker-input {
  width: 100%;
}
/deep/ .ant-calendar-picker-icon {
  color: #93bbf3 !important;
}
/deep/.ant-cascader-picker-label {
  color: #93bbf3 !important;
}
/deep/.ant-cascader-picker {
  background-color: #1c3d6c;
  border: none;
  outline: none;
}
/deep/.ant-table-tbody > tr.ant-table-row-selected td {
  color: inherit;
  background: #1c3d6c;
}
.addData {
  padding: 8px 0;
  border: 1px solid #1c3d6c;
  border-top: 0;
  //   border-bottom: 0;
}
/deep/.list-table .ant-table {
  color: #93bbf3;
}
/deep/.ant-table-scroll
  > .ant-table-header
  > table
  > .ant-table-thead
  > tr
  > th {
  color: #fff;
}
/deep/.ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td {
  background: #1c3d6c;
}
.totalNum {
  border: 1px solid #1c3d6c;
  border-top: 0;
  span {
    width: 128px;
    text-align: center;
    color: #93bbf3;
    height: 46px;
    line-height: 46px;
  }
}
.textarea {
  width: 100%;
  height: 100%;
}
.textarea,
.form-container {
  margin-top: 40px;
}
</style>